
import { defineComponent } from 'vue'
import { DatePicker } from 'v-calendar'

import { download } from '@/helpers/Backend'

enum Game {
  ULTIMATUM = 'ultimatum',
  ZINCIT = 'zinc-it',
  ANCHORING = 'anchoring',
  OUTPSIDER = 'outpsider'
}

export default defineComponent({
  name: 'Export',
  components: {
    DatePicker
  },
  data () {
    return {
      error: undefined,
      disabled: false,
      query: {
        user: {
          id: undefined
        },
        tag: {
          id: undefined
        },
        start: undefined,
        end: undefined,
        game: Game.ULTIMATUM
      }
    } as {
      error?: string,
      disabled: boolean,
      query: {
        user?: {
          id?: number
        },
        tag?: {
          id?: number
        },
        start?: Date,
        end?: Date,
        game: Game
      }
    }
  },
  computed: {
    games (): string[] {
      return Object.values(Game)
    }
  },
  methods: {
    async exporter () {
      this.disabled = true
      try {
        const game = Object.keys(Game)[Object.values(Game).indexOf(this.query.game)].toLowerCase()
        const formatDate = function (date: Date): string | undefined {
          return date.toISOString().split('T').shift()
        }
        await download({
          method: 'GET',
          resource: 'export',
          params: {
            ...(this.query.user?.id) && { userId: this.query.user.id },
            ...(this.query.tag?.id) && { tagId: this.query.tag.id },
            ...(this.query.start) && { startDate: formatDate(this.query.start as Date) },
            ...(this.query.end) && { endDate: formatDate(this.query.end as Date) },
            ...(this.query.game) && { game: game }
          }
        })
      } catch (error) {
        console.log(error)
        this.error = (error as Error).message
      }
      this.disabled = false
    },
    reset () {
      this.query.user = { id: undefined }
      this.query.tag = { id: undefined }
      this.query.start = new Date()
      this.query.end = (() => {
        const date = new Date()
        date.setDate(this.query.start.getDate() + 1)
        return date
      })()
      this.query.game = Game.ULTIMATUM
      this.disabled = false
    }
  },
  mounted () {
    this.disabled = true
    this.error = undefined
    this.reset()
  }
})
